<template>
  <div>
    <Calendar
      v-on:choseDay="clickDay"
      v-on:changeMonth="changeDate"
      :markDateMore="dateArr"
    ></Calendar>
    <div>
      <p class="intetP">
        <mt-button size="small" class="ydk"></mt-button><span>：已打卡</span>
      </p>
      <p class="intetP">
        <mt-button size="small" class="dqrq"></mt-button>：当前日期
      </p>
      <p></p>
    </div>
  </div>
</template>

<script>
import Calendar from "vue-calendar-component";
import { KqInfo } from "utils/interface";
export default {
  name: "categery",
  components: {
    Calendar,
  },
  directives: {},
  data() {
    return {
      dateArr: [],
      userInfo: {},
    };
  },
  mounted() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.userInfo = userInfo;
    this.kqInfo();
  },
  methods: {
    clickDay(data) {
      console.log(data); //选中某天
    },
    changeDate(data) {
      console.log(data); //左右点击切换月份
    },
    clickToday(data) {
      console.log(data); //跳到了本月
    },
    kqInfo() {
      let _that = this;
      let param = {
        applyid: this.userInfo.ApplyId,
        xsid: this.userInfo.XsId,
      };
      KqInfo(param).then((res) => {
        console.log(res);
        let dateArrIn = new Array();
        for (let i in res) {
          dateArrIn.push({
            date: res[i].KqDate.split("-").join("/"),
            className: "mark1",
          });
        }
        _that.dateArr = dateArrIn;
      });
    },
  },
};
</script>

<style>
.ydk {
  background-color: #0000ff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}
.dqrq {
  background-color: #9881cb;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}
.mark1 {
  background-color: #0000ff;
  border-radius: 50%;
}
.mark1::after {
  content: "已签";
  font-size: 12px;
  position: absolute;
  top: 12px;
  color: #82ceeb;
}
.intetP {
  display: flex;
  align-items: center;
}
.wh_content_item .wh_isToday {
  background: #9881cb !important;
}
</style>